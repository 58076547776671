<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="보상번호, 주문번호, 주문상세번호를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="list" autocomplete="off"
                      v-focus></b-form-input>
      </b-input-group>
      <b-form inline class="mb-2">
        <b-dropdown variant="primary" :text="dateKeyMap[form.dateKey]">
          <b-dropdown-item @click="form.dateKey = 'create_date'">보상 발생일</b-dropdown-item>
          <b-dropdown-item @click="form.dateKey = 'order_receive_date'">주문 접수일</b-dropdown-item>
          <b-dropdown-item @click="form.dateKey = 'explain_date'">소명 신청일</b-dropdown-item>
        </b-dropdown>
        <date-from-to :from.sync="form.dateFrom" :to.sync="form.dateTo" v-bind="{init: '1 week', twoDays: true, absMonth: 12, year: 5}" @enter="list()">
        </date-from-to>
      </b-form>

      <template>
        <shop-preset v-model="form.shop"></shop-preset>
        <div class="mb-2">
          <b-form-checkbox v-model="form.real_shop">특수 SHOP (0. 개인결제창, 204. CLEARANCE SALE, 2806. BALAAN REPAIR) 을 제외합니다</b-form-checkbox>
        </div>
      </template>
      <b-collapse id="collapse1" v-model="collapse.detail">
        <b-row>
          <b-col cols="4">
            <small>주문 번호</small>
            <b-form-textarea :rows="3" v-model.trim="orderNos" placeholder="엔터로 구분된 주문번호 혹은 주문상세번호(일련번호)를 입력해주세요"></b-form-textarea>
            <small>보상 번호</small>
            <b-form-textarea :rows="3" v-model.trim="rewardNos" placeholder="엔터로 구분된 보상번호를 입력해주세요"></b-form-textarea>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>한 번에 가져올 보상 수</small><br/>
              <b-form-input class="text-center w-100px" size="sm" title="한 번에 가져올 보상 수" v-model.number="form.limit" @keypress.enter="list"></b-form-input>
            </div>
          </b-col>
          <b-col>
            <div class="flex-row flex-wrap justify-content-start mt-1 mb-4">
              <template v-for="(f, idx) in formCheckOptions.filter(e => !e.role || $R(e.role))">
                <div v-if="f.key === 'divider'" class="w-100" :key="idx"></div>
                <div v-else class="flex-row flex-wrap d-flex justify-content-start mb-2 mr-3" :key="f.key">
                  <div><small class="mb-n2 mr-5">{{ f.name }}</small></div>
                  <b-form-checkbox-group v-model="form[f.key]">
                    <b-button class="mr-3 mb-1" size="sm" variant="primary" @click="toggleOptions(f)">전체 선택</b-button>
                    <b-form-checkbox v-for="s in f.options" :key="s.key" :value="s.key">{{s.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </template>
            </div>
            <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
              <template v-for="(f, idx) in formBtnOptions.filter(e => !e.role || $R(e.role))">
                <div v-if="f.key === 'divider'" class="w-100" :key="idx"></div>
                <div v-else class="flex-grow-0 mb-1 mr-3" :key="f.key">
                  <div><small class="mb-n2">{{ f.name }}</small></div>
                  <b-btn-group class="d-block">
                    <b-btn v-for="o in f.options" size="sm" :key="o.value"
                           :variant="form[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                           @click="o.click ? o.click() : form[f.key] = o.value">
                      {{ o.text }}
                    </b-btn>
                  </b-btn-group>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm">초기화</b-button>
        <b-button class="ml-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
      </div>
    </b-card>
    <b-button v-if="$R('REWARD') " class="mb-2" variant="danger" @click="showModal('createAbusingReward')">고의적 우회 페널티 부여</b-button>
    <b-card>
      <header>
        <div class="clearfix pb-1">
          <span style="font-size: 20px">
            <b>보상 목록</b>
          </span>
        </div>
      </header>
      <div class="clearfix my-2">
        <b-button class="mr-2" size="sm" variant="secondary" @click="selectAllShop">전체 선택/해제</b-button>
        <b-button class="mr-2" size="sm" variant="success" @click="showModal('examineUpdate')">심사 결과 일괄 변경</b-button>
        <div class="pull-right">
          <xlsx :types="['xlsx']" :preFunc="preDown" size="sm" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="Rewards"></xlsx>
        </div>
      </div>
      <hot-table ref="hotTable" :settings="hotSettings"></hot-table>
    </b-card>
    <reward-modal ref="rewardModal" @reloadRewards="list" v-bind="{modal, busy, typeMap, items, colMap, syncData, examineMap}"></reward-modal>
  </div>
</template>

<script>
import {HotTable} from "@handsontable/vue";
import xlsx from "@/views/modules/Xlsx";
import rewardModal from "@/views/order/Reward/RewardModal"
import moment from "moment-timezone";
import dateFromTo from '@/views/modules/DateFromTo.vue'

export default {
  name: 'RewardTab',
  title: '고객보상현황',
  components: {xlsx, HotTable, rewardModal,dateFromTo},
  data() {
    return {
      defaultForm: {
        shop: [],
        examine: 'ALL',
        good_partner: 'ALL',
        reward_status: 'ALL',
        typeMulti: ['firstDelay', 'secondDelay', 'withdraw', 'fakeInvoice', 'cancelPress'],
        explainStatusMulti: ['explainable', 'explainComplete', 'checking', 'examineComplete', 'close'],
        limit: 500,
        skip: 0,
        dateKey: 'create_date',
        dateFrom: moment().add(-1, 'week').format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD')
      },
      form: {shop: []},
      lastBody: {list: {}},
      items: {list: []},
      orderNos: '',
      rewardNos: '',
      busy: {list: false, listmore: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      collapse: {detail: true},
      modal: {createAbusingReward: false, examineUpdate: false, explainDetail: false, diff: false},
      xlsx: {
        selected: [],
        keys: [],
        labels: [],
      },
      shop: [],
      // shopMap: {},
      colMap: {},
      months: [],
      today: moment().format('YYYY-MM-DD'),
      dateKeyMap: {
        create_date: '보상 발생일',
        order_receive_date: '주문 접수일',
        explain_date: '소명 신청일'
      },
      explainStatusMap: {},
      typeMap: {},
      examineMap: {'approve': '승인', 'reject': '반려'},
      formCheckOptions: [
        {
          name: '발생 유형', key: 'typeMulti', options: this.$C.REWARD_TYPE.map(type => ({text: type.name, key: type.code}))
        },
        {
          name: '소명 상태', key: 'explainStatusMulti', options: this.$C.REWARD_EXPLAIN_STATUS.map(type => ({text: type.name, key: type.code}))
        }
      ],
      formBtnOptions: [
        {
          name: '심사 결과', key: 'examine', options: [
            {text: '전체', value: 'ALL'},
            {text: '승인', value: 'approve', variant: 'warning'},
            {text: '반려', value: 'reject', variant: 'danger'},
            {text: '없음', value: null, variant: 'dark'}
          ]
        },
        {
          name: '우수 파트너 여부', key: 'good_partner', options: [
            {text: '전체', value: 'ALL'},
            {text: 'Y', value: true, variant: 'primary'},
            {text: 'N', value: false, variant: 'primary'},
          ]
        },
        {
          name: '고객 보상', key: 'reward_status', options: [
            {text: '전체', value: 'ALL'},
            {text: '예정', value: false, variant: 'warning'},
            {text: '완료', value: true, variant: 'primary'},
          ]
        },
      ],
      hotSettings: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.items.list.filter(e => e.selected).length}/${this.items.list.length}`;
          }
          return this.fields.reward.map(e => e.name)[index];
        },
        columns:  [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 650,
        fixedColumnsLeft: 2,
        licenseKey: 'non-commercial-and-evaluation',
        className: 'htCenter htMiddle',
        afterScrollVertically: () => {
          const hot = this.$refs.hotTable.hotInstance;
          if (hot.getPlugin('autoRowSize').getLastVisibleRow() + 10 >= hot.countRows() && !this.busy.listmore && this.hasMore.list) { // 마지막 10 row 가 보인다면
            this.list(true);
          }
        },
      },
      fields: {
        reward: [
          {data: 'selected', type: 'checkbox'},
          {data: 'no',  name: '보상번호', width: 100, readOnly: true},
          {data: 'shop_id',  name: 'SHOP ID', width: 100, readOnly: true},
          {data: 'boutique',  name: '파트너명', width: 200,readOnly: true},
          {data: 'orderno',  name: '주문번호', width: 200,readOnly: true},
          {data: 'order_detailno',  name: '주문상세번호',width: 150, readOnly: true},
          {data: 'order_receive_date',  name: '주문 접수일',width: 150, readOnly: true},
          {data: 'sales_price', name: '발란몰 판매금액', width: 120, readOnly: true},
          {data: 'type_str',  name: '발생 유형', width: 150,readOnly: true},
          {data: 'reward_price',  name: '보상 금액',width: 100, readOnly: true},
          {data: 'create_date',  name: '보상 발생일',width: 150, readOnly: true},
          {data: 'explain_status_str',  name: '소명 상태',width: 100, readOnly: true},
          {data: 'explain_term_date',  name: '소명 기간',width: 110, readOnly: true},
          {data: 'explain_date',  name: '소명 신청일', width: 150, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              if (td && value !== '-') {
                td.className = 'htCenter htMiddle';
                td.innerHTML = `<button class="btn btn-light btn-sm">${value}</button>`;
                let arr = this.$refs.hotTable.hotInstance.getDataAtRow(row); // [null, no, shop_id, boutique, orderno ...]
                const no = arr[1];

                const [item] = this.items.list.filter(i => i.no === no);
                td.firstChild.onclick = _ => this.showModal('explainDetail', item);
              } else {
                td.className = 'htCenter htMiddle';
                td.innerHTML = value;
              }
            }},
          {data: 'examine_term_date',  name: '심사 기간', width: 110, readOnly: true},
          {data: 'examine_str',  name: '심사 결과', width: 100, readOnly: true},
          {data: 'good_partner',  name: '우수 파트너',width: 100, readOnly: true},
          {data: 'examine_complete_date',  name: '심사 완료일',width: 100, readOnly: true},
          {data: 'is_penalty',  name: '페널티 여부',width: 100, readOnly: true},
          {data: 'settle',  name: '정산 차감액',width: 100, readOnly: true},
          {data: 'expected_settlement_date',  name: '정산 차감일', width: 100,readOnly: true},
          {data: 'reward_status',  name: '고객 보상', width: 70,readOnly: true},
          {data: 'reward_complete_date',  name: '보상 완료일', width: 150, readOnly: true},
        ]
      }
    }
  },
  async created() {
    this.$C.REWARD_EXPLAIN_STATUS.forEach(s => this.explainStatusMap[s.code] = s.name)
    this.$C.REWARD_TYPE.forEach(s => this.typeMap[s.code] = s.name)
    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM-DD'));
      monSt.add(-1, 'month');
    }
    let meta = await this.$api.getMeta('shop');
    if (!meta) return;
    meta.shop.forEach(s=>{
      s.value = s.shop_id;
      s.label = `${s.shop_id}. ${s.boutique}`;
    });
    this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);

    this.resetForm();
    this.hotSettings.columns = this.fields.reward;
    await this.list();
    this.fields.reward.forEach(f => this.colMap[f.data] = f.name)
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  methods: {
    async list (more) {
      let orderNos = this.orderNos ? this.orderNos.split(/\r?\n/g).map(e=>e.trim()) : [];
      let rewardNos = this.rewardNos ? this.rewardNos.split(/\r?\n/g).map(e=>e.trim()) : [];

      let form = this.form;
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      await this.$api.postTable(this, '/order/reward/list', {...form, shop, orderNos, rewardNos}, {more, fnAssign: this.assignTableData})
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    assignTableData (row) {
      row.explain_status_str = this.explainStatusMap[row.explain_status];
      row.type_str = this.typeMap[row.type];
      row.is_penalty = !row.good_partner ? row.examine !== 'approve' : false;
      row.is_penalty = row.is_penalty ? 'Y' : 'N';
      if (!row.is_settle_complete) {
        row.settle = '-';
        row.expected_settlement_date = '-';
      }
      row.good_partner = row.good_partner ? 'Y' : 'N';
      row.reward_status = row.reward_status ? '완료' : '예정';
      row.examine_str = this.examineMap[row.examine];
      this.fields.reward.map(f => f.data).forEach(key => {
        if (key !== 'selected' && [undefined, null].includes(row[key])) row[key] = '-';
      });
      row.explain_term_date = `${row.explain_start_date} ~ ${row.explain_deadline}`;
      row.examine_term_date = `${this.$utils.kstD(this.$moment(row.explain_deadline).add(1, 'day'))} ~ ${row.examine_deadline}`;
      row.explain_date = row.explain_status === 'close' ? row.explain_status_str : row.explain_date;
      if (row.explain && row.explain.explain_file) {
        row.explain.explain_file = this.$utils.typeOf(row.explain.explain_file) === 'array' ? row.explain.explain_file : [row.explain.explain_file];
      }
    },
    resetForm() {
      this.form = this.$utils.clone(this.defaultForm);
    },
    selectAllShop() {
      let selectAll = !this.items.list.every(e => e.selected);
      this.items.list.forEach(e => e.selected = selectAll);
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    async preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);
      this.xlsx.selected.forEach(item => {
        if (item.explain && item.explain.explain_content) item.explain_content = item.explain.explain_content || '';
        if (item.examine_log && item.examine_log.length) {
          item.examine_log = item.examine_log.filter(e => e.content && !e._del_dt).map(e=>`${e._dt} [${e._name}] ${e.content}`).join('\n');
        } else {
          item.examine_log = '';
        }
      });

      const xlsxValue = [
        {data: 'no',  name: '보상번호'},
        {data: 'shop_id',  name: 'SHOP ID'},
        {data: 'boutique',  name: '파트너명'},
        {data: 'orderno',  name: '주문번호'},
        {data: 'order_detailno',  name: '주문상세번호'},
        {data: 'order_receive_date',  name: '주문 접수일'},
        {data: 'sales_price',  name: '발란몰 판매금액'},
        {data: 'type_str',  name: '발생 유형'},
        {data: 'reward_price',  name: '보상 금액'},
        {data: 'create_date',  name: '보상 발생일'},
        {data: 'explain_term_date',  name: '소명 기간'},
        {data: 'explain_status_str',  name: '소명 상태'},
        {data: 'explain_content',  name: '소명 내용'},
        {data: 'explain_date',  name: '소명 신청일'},
        {data: 'examine_term_date',  name: '심사 기간'},
        {data: 'examine_str',  name: '심사 결과'},
        {data: 'good_partner',  name: '우수 파트너'},
        {data: 'examine_complete_date',  name: '심사 완료일'},
        {data: 'is_penalty',  name: '페널티 여부'},
        {data: 'settle',  name: '정산 차감액'},
        {data: 'expected_settlement_date',  name: '정산 차감일'},
        {data: 'reward_status',  name: '고객 보상'},
        {data: 'reward_complete_date',  name: '보상 완료일'},
        {data: 'examine_log',  name: '심사 Log'},
      ]

      if (!this.xlsx.selected.length) {
        return alert('다운받을 정보를 선택해주세요.');
      }

      this.xlsx.keys = xlsxValue.map(f=> f.data);
      this.xlsx.labels = xlsxValue.map(f=> f.name);

      return true;
    },
    showModal(type, row) {
      this.$refs.rewardModal.showModal(type, row);
    },
    syncData(item, keys) {
      keys = keys.split(',')
      this.items.list.forEach(i => {
        if (i.no === item.no) {
          keys.forEach(k => i[k] = item[k])
        }
      });

      this.$refs.hotTable.hotInstance.render();
    },
    toggleOptions(formCheck) {
      if (this.form[formCheck.key].length !== formCheck.options.map(o => o.key).length) {
        this.form[formCheck.key] = formCheck.options.map(o => o.key);
      } else {
        this.form[formCheck.key] = [];
      }
    }
  }
}

</script>
