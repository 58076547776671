<template>
  <div>
    <b-modal size="lg" title="우수 파트너 지정" v-model="modal.goodPartnerUpdate" ok-title="확인" cancel-title="취소" @ok="upadateResult">
      <b-alert show variant="info">
        선택한 파트너 {{items.list.filter(e => e.selected).length}}개에 대하여 우수 파트너 여부를 반영합니다.
      </b-alert>
      <strong>우수 파트너 여부</strong><span class="ml-1 text-danger">*</span>
      <b-form-radio-group class="col-form-label mb-2" v-model="good_partner" :options="[
            {text: 'Y', value: true},
            {text: 'N', value: false},
          ]"></b-form-radio-group>
      <b-alert show variant="danger">
        우수 파트너 여부는 10일 23:59:59 까지 수정 가능하며, 파트너센터 11일 00:20:00 경에 일괄 반영됩니다.
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: 'GoodPartnerModal',
  props: ['modal', 'items'],
  data() {
    return {
      item: {},
      abusingReward: {oids: '', type: ''},
      good_partner: '',
      diffHtml: '',
      today: moment().format('YYYY-MM-DD'),
      isAbleExamine: false,
      selection_ym: '2023-03',
    }
  },
  methods: {
    showModal(type) {
      this.abusingReward = {oids: '', type: ''};
      this.good_partner = '';
      this.modal[type] = true;

      if (type === 'goodPartnerUpdate') {
       const {ok, msg} = this.updateValidation();
       if (!ok) {
         alert(msg)
         this.modal[type] = false;
         return;
       }
      }
    },
    hideModal() {
      this.modal.explainDetail = false;
    },
    async upadateResult(e) {
      const selected = this.items.list.filter(e => e.selected);

      if (this.good_partner === '') {
        alert('우수 파트너 여부를 선택해주세요.');
        e.preventDefault && e.preventDefault();
        return;
      }

      const baseRate = selected.filter(item => item.int_delay_rate > 2 || item.int_withdraw_rate > 2
          || item.int_delay_rate === null || item.int_withdraw_rate === null || item.fakeInvoice_cnt > 0 || item.cancelPress_cnt > 0);
      if (baseRate.length) {
        alert('발송 지연율 또는 품절률이 2% 초과이거나 \n고의적 우회 행위 (가송장, 취소종용) 가 있는 파트너사가 존재합니다.');
        e.preventDefault && e.preventDefault();
        return;
      }

      const examineComplete = selected.filter(item => item.examine_complete === 'N')
      if (examineComplete.length) {
        alert('심사 결과가 완료되지 않은 파트너사가 존재합니다.');
        e.preventDefault && e.preventDefault();
        return;
      }

      if (!confirm(`${selected.length}개 파트너에 대하여 우수파트너 여부를 ${this.good_partner ? 'Y' : 'N'}로 업데이트 합니다.`)) {
        e.preventDefault && e.preventDefault();
        return;
      }

      const j = await this.$api.postJson('/order/reward/updateGoodPartner',
          {shops: selected.map(item => item.shop_id), good_partner: this.good_partner, selection_ym: this.selection_ym});
      if (j && j.ok) {
        this.$emit('reloadGoodPartner');
        return alert(`${selected.length}개 파트너에 대하여 우수파트너 여부가 ${this.good_partner ? 'Y' : 'N'}로 업데이트 되었습니다.`);
      }
    },
    updateValidation() {
      const selected = this.items.list.filter(e => e.selected);

      if (!selected.length) {
        return {ok: 0, msg: '변경할 내용을 선택해주세요.'};
      }
      this.selection_ym = selected[0].selection_ym;
      const startDate = this.monthDateCalc(this.selection_ym, '06');
      const endDate = this.monthDateCalc(this.selection_ym, '10');
      if ( this.today < startDate || this.today > endDate) return {ok: 0, msg: '우수파트너 지정 기간이 아닙니다.'};
      return {ok: 1}
    },
    monthDateCalc(ym, day){
      return moment(ym + '-' + day).add(1, 'month').format('YYYY-MM-DD');
    }
  }
}
</script>