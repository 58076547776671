<template>
  <div>
    <b-modal size="lg" title="고의적 우회 페널티 부여" v-model="modal.createAbusingReward" ok-title="확인" cancel-title="취소" @ok="createAbusingReward">
      <b-alert show variant="info">
        OID (주문번호|주문상세번호) 를 입력하여 고의적 우회 페널티를 부여합니다.
        <br>
        페널티 부여 시 파트너센터 및 허브 고객 보상 현황에 보상 건이 추가됩니다.
      </b-alert>
      <strong>발생 유형</strong><span class="ml-1 text-danger">*</span>
      <b-form-radio-group class="col-form-label mb-2" v-model="abusingReward.type" :options="[
            {text: '가송장', value: 'fakeInvoice'},
            {text: '취소 종용', value: 'cancelPress'},
          ]"></b-form-radio-group>
      <b-row>
        <b-col cols="8">
          <strong>OID(주문번호|주문상세번호)</strong><span class="ml-1 text-danger">*</span>
          <b-form-textarea :rows="3" v-model.trim="abusingReward.oids" placeholder="쉼표 (,) 또는 엔터로 구분된 OID 를 주문번호|주문상세번호 형태로 입력해주세요"></b-form-textarea>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal size="lg" title="심사 결과 일괄 변경" v-model="modal.examineUpdate" ok-title="확인" cancel-title="취소" @ok="updateExamineResult">
      <b-alert show variant="info">
        선택한 보상 건 {{items.list.filter(e => e.selected).length}}건에 대하여 심사 결과를 일괄 반영 합니다.
      </b-alert>
      <strong>심사 결과</strong><span class="ml-1 text-danger">*</span>
      <b-form-radio-group class="col-form-label mb-2" v-model="examine.examine" :options="[
            {text: '승인', value: 'approve'},
            {text: '반려', value: 'reject'},
          ]"></b-form-radio-group>
      <b-row>
        <b-col cols="8">
          <strong>심사 Log</strong>
          <b-input class="my-2" :rows="3" v-model.trim="examine.examine_log" placeholder=""></b-input>
        </b-col>
      </b-row>
      <b-alert show variant="danger">
        주의문구: 가송장, 취소 종용의 경우 반려 처리 시 고객에게 즉시 보상 적립금이 부여됩니다.
      </b-alert>
    </b-modal>

    <b-modal size="lg" title="소명 내용" v-model="modal.explainDetail" @hide="modalHide">
      <template v-slot:modal-title>
        <div>소명 내용</div>
      </template>
      <b-row class="ml-3">
        <b-col cols="12" md="8">
          <b-form-group label-cols="4" :label="'SHOP ID / 파트너명'">
            <div class="col-form-label" a="">{{`${item.shop_id}. ${item.boutique}`}}</div>
          </b-form-group>
          <b-form-group label-cols="4" :label="'주문번호|일련번호'">
            <div class="col-form-label">
              <a v-if="PRIVATE" :href="`/#/deliveryBoard/${item.oid}`" target="_blank">{{item.oid}}</a>
              <span v-else>{{item.oid}}</span>
              <br/>
            </div>
          </b-form-group>
          <b-form-group label-cols="4" :label="'발생유형'">
            <div class="col-form-label">{{typeMap[item.type]}}</div>
          </b-form-group>
          <b-form-group label-cols="4" :label="'증빙자료'">
            <div class="col-form-label text-truncate" style="width:200px;" v-if="item.explain && item.explain.explain_file">
              <div v-for="(file) in item.explain.explain_file" :key="file.key">
                <a :href="`https://files.balaan.io/${file.key}`" target="_blank">
                  <i class="fa fa-file-o"></i>
                  {{file.originalname}}
                </a>
              </div>
            </div>
            <div class="col-form-label" v-if="item.explain && item.explain.explain_file">

            </div>
          </b-form-group>
          <b-form-group label-cols="4" :label="'소명내용'">
            <div class="col-form-label" v-if="item.explain && item.explain.explain_content">{{item.explain.explain_content || ''}}</div>
          </b-form-group>
          <b-form-group label-cols="4" :label="'심사 결과'">
            <b-form-radio-group class="col-form-label" :disabled="isAbleExamine" v-model="item.examine" :options="[
            {text: '승인', value: 'approve'},
            {text: '반려', value: 'reject'},
          ]"></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4" class="border-left">
          <template>
            <label>심사 LOG</label>
            <i class="fa fa-question-circle ml-1" v-b-tooltip="'[권장 가이드] 용량 : 50MB 이하 / 등록 가능 확장자 : jpg, jpeg, png, bmp, webp'"></i>
            <div data-type="ps" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover">
              <perfect-scrollbar ref="examine_log" style="max-height:400px;overflow-y:auto;overflow-x:hidden">
                <div v-for="i in (item.examine_log || []).slice().reverse()">
                  <template v-if="!i._del_dt">
                    <div class="message">
                      <div>
                        <small class="text-muted">{{i._name}}</small>
                        <small class="text-muted float-right mt-1" @mouseover="_=>{i._show_del = true;$forceUpdate()}" @mouseleave="_=>{i._show_del = false;$forceUpdate()}">
                          <b-badge variant="danger"><i class="fa fa-close pointer" @click="removeLog('examine', i)" v-if="i._show_del"></i></b-badge><div class="d-inline-block ml-1">{{i._dt}}</div>
                        </small>
                      </div>
                      <template v-if="i.type === 'file'">
                        <a :href="i.url" target="_blank"><i class="fa fa-file-o"></i> {{i.name}}</a>
                        <div></div>
                      </template>
                      <a v-else-if="i.type === 'image'" :href="i.url" target="_blank"><img :src="i.thumbs[100].url" style="max-width:100px; max-height:150px;"></a>
                      <div v-else class="font-weight-bold" v-html="i.content.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
                    </div>
                    <hr>
                  </template>
                </div>
              </perfect-scrollbar>
              <div v-if="!item.examine_log || item.examine_log.filter(e=>!e._del_dt).length === 0">
                <div class="mt-3 mb-3 text-center font-weight-bold">심사 Log가 없습니다</div>
              </div>
              <b-textarea ref="examine_log_text" data-type="examine" @drop.prevent.stop="uploadFile" @dragover="handleDragover" @dragenter="handleDragover"></b-textarea>
              <input type="file" ref="examine_log_file" data-type="examine" style="display: none" @change="uploadFile" :disabled="busy.examineLog">
              <div class="text-right mt-1">
                <b-button class="mr-1" size="sm" variant="light"><i class="fa fa-cloud-upload fs-16" @click="_=>{$refs.examine_log_file.value = null;$refs.examine_log_file.click()}"></i></b-button>
                <b-button class="" size="sm" variant="success" @click="addLog('examine')" :disabled="busy.examineLog">저장<b-spinner class="ml-1" small v-if="busy.examineLog"></b-spinner></b-button>
              </div>
            </div>
          </template>
        </b-col>
      </b-row>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('ORDER_X') || $G('dev')" variant="outline-light" @click="$modal.show({type:'json', item})">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
        <b-button variant="primary" :disabled="isAbleExamine" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>

    <b-modal title="변경사항 확인" size="lg" v-model="modal.diff">
      <span v-html="diffHtml"></span>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="()=>{modal.diff=false;modalHide({trigger:'ok'})}">
          저장하고 닫기
        </b-button>
        <b-button size="sm" variant="danger" @click="()=>{modal.diff=false;hideModal()}">
          저장하지 않고 닫기
        </b-button>
        <b-button size="sm" variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: 'RewardModal',
  props: ['modal', 'busy', 'typeMap', 'items', 'colMap', 'syncData', 'examineMap'],
  data() {
    return {
      item: {},
      item_org: {},
      abusingReward: {oids: '', type: ''},
      examine: {examine: '', examine_log: ''},
      diffHtml: '',
      today: moment().format('YYYY-MM-DD'),
      isAbleExamine: false
    }
  },
  methods: {
    showModal(type, row) {
      this.abusingReward = {oids: '', type: ''};
      this.examine = {examine: '', examine_log: ''};
      this.modal[type] = true;
      if (type === 'examineUpdate') {
       const {ok, msg} = this.updateExamineValidation();
       if (!ok) {
         alert(msg)
         this.modal[type] = false;
         return;
       }
      }
      if (row) {
        this.isAbleExamine = this.ableExamine([row]) || row.examine ? true : false;
        row.examine_log = row.examine_log || [];
        this.item_org = row;
        this.item = this.$utils.clone(row);
        this.examine = {examine: this.item.examine}
      }
    },
    showDiffModal(item) {
      this.$modal.show({
        title: '수정이력 확인',
        type: 'diff',
        item: item,
        colMap: this.colMap,
        db: 'balis',
        collection: 'deliver_boards'
      });
    },
    hideModal() {
      this.modal.explainDetail = false;
    },
    async modalHide(event) {
      // console.log(event, event.trigger);
      const CHECK_COL = ('examine').split(',');

      CHECK_COL.forEach(e => {
        if (typeof this.item[e] === 'string') {
          this.item[e] = this.item[e].trim();
        }
      });
      let modifiedCols = CHECK_COL.filter(e=> JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));

      if (event.trigger === 'ok') {
        if (modifiedCols.length) {
          event.preventDefault && event.preventDefault();
          let item = {no: this.item.no};
          modifiedCols.forEach(e => item[e] = this.item[e]);

          const body = {reward_nos: [item.no], examine: item.examine}
          let j = await this.$api.postJson('/order/reward/updateExamine', body);
          if (j && j.ok) {
            this.item_org = this.item;
            alert('수정 내용이 저장되었습니다.');
            this.item.explain_status = 'examineComplete';
            this.item.explain_status_str = '심사완료';
            this.item.examine_str = this.examineMap[this.item.examine];
            this.item.examine_complete_date = this.today
            this.syncData(this.item, 'examine,explain_status,explain_status_str,examine_complete_date,examine_str');
            this.modal.explainDetail = false;
          }
        }
      } else if (~['cancel', 'headerclose', 'esc', 'backdrop'].indexOf(event.trigger)) {
        if (modifiedCols.length) {
          if (event.trigger === 'cancel' || event.trigger === 'headerclose') {
            this.modal.diff = true;
            this.diffHtml = modifiedCols.map(e => `<span class="badge badge-light">${this.colMap[e] || e}</span> ${this.item_org[e] === undefined ? '값 없음' : `'${this.item_org[e]}'`} → '${this.item[e]}'`).join('<br/>');
            event.preventDefault && event.preventDefault();
            // if (!confirm('변경사항을 저장하지 않으시겠습니까?')) {
            //   event.preventDefault();
            // }
          } else {
            event.preventDefault && event.preventDefault(); // 이벤트 무시
          }
        } else { // 변경사항이 없으면
          // pass
        }
      }
    },
    async updateExamineResult(e) {
      const selected = this.items.list.filter(e => e.selected);

      if (!this.examine.examine) {
        alert('심사 결과를 선택해주세요.');
        e.preventDefault && e.preventDefault();
        return;
      }

      const noExplain_date = selected.filter(item => !item.explain_date);
      if (noExplain_date.length) {
        alert('소명 신청하지 않은 항목이 존재합니다.');
        e.preventDefault && e.preventDefault();
        return;
      }

      const settlementComplete = selected.filter(item => item.expected_settlement_date !== '-')
      if (settlementComplete.length) {
        alert('정산 차감이 완료된 항목이 존재합니다.');
        e.preventDefault && e.preventDefault();
        return;
      }

      if (!confirm(`${selected.length}건에 대하여 심사결과를 ${this.examine.examine === 'approve' ? '승인' : '반려'}로 업데이트 합니다.`)) {
        e.preventDefault && e.preventDefault();
        return;
      }

      const j = await this.$api.postJson('/order/reward/updateExamine', {...this.examine, reward_nos: selected.map(item => item.no)});
      if (j && j.ok) {
        this.$emit('reloadRewards');
        return alert(`${selected.length}건에 대하여 심사결과를 ${this.examine.examine === 'approve' ? '승인' : '반려'}로 업데이트 되었습니다.`);
      }
    },
    async createAbusingReward (e) {
      if (!this.abusingReward.type) {
        alert('페널티 유형을 선택해주세요.');
        e.preventDefault && e.preventDefault();
        return;
      }
      if (!this.abusingReward.oids) {
        alert('OID를 입력해주세요.');
        e.preventDefault && e.preventDefault();
        return;
      }
      const oids = this.abusingReward.oids.split(/\n|,/g);
      const noOids = oids.filter(oid => !/^\d{13}\\|\d{6,8}$/.test(oid));
      if (noOids.length) {
        alert('OID 입력 양식이 올바르지 않습니다.');
        e.preventDefault && e.preventDefault();
        return;
      }

      if (!confirm(`${oids.length}건의 주문에 대해 ${this.typeMap[this.abusingReward.type]} 페널티를 부여하시겠습니까?`)) {
        e.preventDefault && e.preventDefault();
        return;
      }

      const form = {type: this.abusingReward.type, oids};
      const j = await this.$api.postJson('/order/reward/createReward', {
        ...form
      });
      if (j && j.ok) {
        this.$emit('reloadRewards');

        const msg = `선택한 ${oids.length}건의 주문중 ${j.cnt} 건에 대해 ${this.typeMap[this.abusingReward.type]} 페널티가 부여되었습니다.`
        + (Object.entries(j.errorMap).length ? `\n(${Object.entries(j.errorMap).map(([k, v]) => `${k}:${v}`).join(',')})` : '')
        return alert(msg);
      }
    },
    async addLog(part) {
      let _log = part + '_log', _text = part + '_log_text';
      let content = this.$refs[_text].$el.value.trim();
      if (content) {
        let log = {content, _dt: this.$moment().format('YYYY-MM-DD HH:mm:ss'), _id:this.$S.user.id, _name:this.$S.user.name};
        this.item[_log].push(log);
        setTimeout(()=>{
          let container = this.$refs[_log];
          container.scrollTop = container.scrollHeight;
          this.$refs[_text].$el.value = '';
          this.$refs[_text].focus();
        });
        let j = await this.$api.postJson('/order/reward/updateLog', {type:'add', part, item: {no: this.item.no, log: log}});
        if (!j) this.item[_log].pop();
        this.$forceUpdate();
        this.syncData(this.item, 'examine_log');
      }
    },
    async uploadFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      let typeEl = event.target;
      while (!typeEl.dataset.type && typeEl !== document.body) {
        typeEl = typeEl.parentElement;
      }

      const type = typeEl.dataset.type;
      if (!file) return this.$utils.alert('파일을 업로드해주세요');
      const uploadableExts = 'jpg,jpeg,png,bmp,webp'.split(',');
      if (!file.name.toLowerCase().split('.').pop().in(uploadableExts)) {
        return this.$utils.alert('jpg, jpeg, png, bmp, webp 파일을 업로드해주세요');
      }
      if (file.size > 50 * 1024 * 1024) { // 파일 용량 제한 50MB
        return this.$utils.alert('50MB 이하 파일만 업로드 가능합니다.');
      }

      this.busy[`${type}Log`] = true;
      let j = await this.$api.postForm('/order/reward/appendFile', {file, no: this.item.no, type});
      this.busy[`${type}Log`] = false;
      if (!j) return;

      const logField = `${type}_log`;
      this.item[logField].push(j.log);
      this.syncData(this.item, 'examine_log');
      setTimeout(()=>{
        let container = this.$refs[logField];
        container.scrollTop = container.scrollHeight;
      });
    },
    async removeLog(part, log) {
      let _log = part + '_log';
      if (confirm('해당 Log를 삭제하시겠습니까?')) {
        log._del_dt = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        this.$forceUpdate();
        let j = await this.$api.postJson('/order/reward/updateLog', {type:'remove', part, item: {no: this.item.no, log: log}});
        if (!j) {
          delete log._del_dt;
        }
        this.$forceUpdate();
        this.item_org[_log] = this.item[_log];
        this.syncData(this.item, 'examine_log');
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    updateExamineValidation() {
      const selected = this.items.list.filter(e => e.selected);

      if (!selected.length) {
        return {ok: 0, msg: '변경할 내용을 선택해주세요.'};
      }
      if (selected.filter(e => e.explain_date === '-' || e.examine || e.expected_settlement_date !== '-').length) {
        return {ok: 0, msg: '심사 결과 변경은 아래 조건을 모두 만족한 항목에 대해서 가능합니다\n' +
              '- 심사 결과가 없는 항목\n' +
              '- 정산 차감이 되지 않은 항목'};
      }

      const noTarget = this.ableExamine(selected);
      if (noTarget) {
        return {ok: 0, msg: '심사 기간이 아닙니다'};
      }
      return {ok: 1}
    },
    ableExamine(row) {
      const noTarget = row.filter(item => item.examine_deadline < this.today || item.explain_deadline >= this.today);
      return noTarget.length ? true : false
    }
  }
}
</script>
