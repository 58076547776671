<template>
  <b-tabs class="mt-3" v-model="tabIndex">
    <b-tab title="고객보상현황">
      <reward-tab v-bind="{}"></reward-tab>
    </b-tab>
    <b-tab title="우수파트너 지정">
      <goodPartner-tab v-if="loaded[1] || tabIndex === 1"></goodPartner-tab>
    </b-tab>
  </b-tabs>
</template>

<script>
import RewardTab from './RewardTab';
import GoodPartnerTab from './GoodPartnerTab';

export default {
  name: 'Reward',
  title: '고객보상현황',
  components: {RewardTab, GoodPartnerTab},
  data() {
    return {
      tabIndex: 0,
      loaded: {},
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
