<template>
  <div>
    <b-card>
      <b-form inline class="mb-2">
        <b-input v-model="month" :readonly="true"></b-input>
        <b-button-group>
          <b-dropdown variant="success" text="월">
            <b-dropdown-item v-for="m in months" @click="setDate(m)" :key="m">{{ m.substring(0, 7) }}</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-form>
      <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
        <shop-preset v-model="form.shop"></shop-preset>
        <div class="mb-2">
          <b-form-checkbox v-model="form.real_shop">특수 SHOP (0. 개인결제창, 204. CLEARANCE SALE, 2806. BALAAN REPAIR) 을 제외합니다</b-form-checkbox>
        </div>
      </template>
      <b-row>
        <b-col>
          <div class="flex-row flex-wrap d-flex justify-content-start mt-1">
            <template v-for="(f, idx) in formBtnOptions.filter(e => !e.role || $R(e.role))">
              <div v-if="f.key === 'divider'" class="w-100" :key="idx"></div>
              <div v-else class="flex-grow-0 mb-1 mr-3" :key="f.key">
                <div><small class="mb-n2">{{ f.name }}</small></div>
                <b-btn-group class="d-block">
                  <b-btn v-for="o in f.options" size="sm" :key="o.value"
                         :variant="form[f.key] === o.value ? (o.variant || (o.value === 'ALL' ? 'dark' : '')): 'light'"
                         @click="o.click ? o.click() : form[f.key] = o.value">
                    {{ o.text }}
                  </b-btn>
                </b-btn-group>
              </div>
              <div class="flex-grow-0 mb-1 mr-2">
                <small>limit</small><br/>
                <b-form-input class="text-center w-100px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.limit" @keypress.enter="list"></b-form-input>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <div class="mt-2 clearfix">
        <b-button class="" variant="primary" @click="list" :disabled="busy.list">
          검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
        </b-button>
        <b-button class="ml-1" variant="warning" @click="resetForm">초기화</b-button>
      </div>
    </b-card>
    데이터 업데이트 시각: {{this.updateDt}}
    <b-card>
      <header>
        <div class="clearfix pb-1">
          <span style="font-size: 20px">
            <b>우수파트너 현황</b>
          </span>
        </div>
      </header>
      <div class="clearfix my-2">
        <b-button class="mr-2" size="sm" variant="secondary" @click="selectAllItem">전체 선택/해제</b-button>
        <b-button class="mr-2" size="sm" variant="success" @click="showModal('goodPartnerUpdate')">우수파트너 일괄 변경</b-button>
        <div class="pull-right">
          <b-button class="mr-2" size="sm" variant="primary" :disabled="busy.dataUpdate" @click="DataUpdate()">심사 결과 데이터 갱신
            <b-spinner class="ml-1" small v-if="busy.dataUpdate"></b-spinner></b-button>
          <xlsx :types="['xlsx']" :preFunc="preDown" size="sm" :data="xlsx.selected" :labels="xlsx.labels" :keys="xlsx.keys" name="WaiverPartner"></xlsx>
        </div>
      </div>
      <hot-table ref="hotTable" :settings="hotSettings"></hot-table>
    </b-card>
    <goodPartner-modal ref="GoodPartnerModal" @reloadGoodPartner="list" v-bind="{modal, items}"></goodPartner-modal>
  </div>
</template>

<script>
import {HotTable} from "@handsontable/vue";
import xlsx from "@/views/modules/Xlsx";
import GoodPartnerModal from "@/views/order/Reward/GoodPartnerModal"
import moment from "moment-timezone";

export default {
  name: 'GoodPartnerTab',
  title: '우수파트너 현황',
  components: {xlsx, HotTable, GoodPartnerModal},
  data() {
    return {
      defaultForm: {
        shop: [],
        good_partner: 'ALL',
        limit: 2000,
        skip: 0,
      },
      form: {shop: []},
      items: {list: []},
      busy: {list: false, listmore: false, dataUpdate: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {goodPartnerUpdate: false, orderStatUpdate: false, diff: false},
      xlsx: {
        selected: [],
        keys: [],
        labels: [],
      },
      shop: [],
      colMap: {},
      months: [],
      month: moment().add("-1", "M").format('YYYY-MM'),
      updateDt: '',
      formBtnOptions: [
        {
          name: '우수 파트너 여부', key: 'good_partner', options: [
            {text: '전체', value: 'ALL'},
            {text: 'Y', value: true, variant: 'primary'},
            {text: 'N', value: null, variant: 'primary'},
          ]
        }
      ],
      hotSettings: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.items.list.filter(e => e.selected).length}/${this.items.list.length}`;
          }
          return this.fields.goodPartners.map(e => e.name)[index];
        },
        columns:  [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 650,
        fixedColumnsLeft: 2,
        licenseKey: 'non-commercial-and-evaluation',
        className: 'htCenter',
        afterScrollVertically: () => {
          const hot = this.$refs.hotTable.hotInstance;
          if (hot.getPlugin('autoRowSize').getLastVisibleRow() + 10 >= hot.countRows() && !this.busy.listmore && this.hasMore.list) { // 마지막 10 row 가 보인다면
            this.list(true);
          }
        },
      },
      fields: {
        goodPartners: [
          {data: 'selected', type: 'checkbox' , width: 100} ,
          {data: 'selection_ym',  name: '기간', width: 100, readOnly: true},
          {data: 'shop_id',  name: 'SHOP ID', width: 80, readOnly: true},
          {data: 'boutique',  name: '파트너명', width: 230,readOnly: true},
          {data: 'good_partner',  name: '우수 파트너', width: 100,readOnly: true},
          {data: 'good_partner_dt',  name: '우수 파트너 지정일',width: 150, readOnly: true},
          {data: 'total_order_cnt',  name: '총 주문 수',width: 130, readOnly: true},
          {data: 'delay_cnt',  name: '지연 주문수', width: 100,readOnly: true},
          {data: 'delay_rate',  name: '발송 지연율',width: 100, readOnly: true},
          {data: 'withdraw_cnt',  name: '품절 주문수',width: 100, readOnly: true},
          {data: 'withdraw_rate',  name: '품절률',width: 100, readOnly: true},
          {data: 'fakeInvoice_cnt',  name: '가송장 주문수', width: 100, readOnly: true},
          {data: 'cancelPress_cnt',  name: '취소 종용 주문수', width: 120, readOnly: true},
          {data: 'examine_complete',  name: '심사 마감', width: 100, readOnly: true},
        ]
      }
    }
  },
  async created() {
    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-12, 'month')) {
      this.months.push(monSt.format('YYYY-MM'));
      monSt.add(-1, 'month');
    }
    let meta = await this.$api.getMeta('shop');
    if (!meta) return;
    meta.shop.forEach(s=>{
      s.value = s.shop_id;
      s.label = `${s.shop_id}. ${s.boutique}`;
    });
    this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);

    this.resetForm();
    this.hotSettings.columns = this.fields.goodPartners;
    await this.list();
    this.fields.goodPartners.forEach(f => this.colMap[f.data] = f.name)
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
  methods: {
    async list (more) {
      let form = this.form;
      let month = this.month;
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e=>e.shop_id); // 전체 선택일 경우 비우기
      await this.$api.postTable(this, '/order/reward/goodPartnerlist', {...form, shop, month}, {more, fnAssign: this.assignTableData})
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
      this.updateDt = this.items.list.length && this.items.list[0]._dt;
    },
    assignTableData (row) {
      row.good_partner = row.good_partner ? 'Y' : 'N';
      row.int_delay_rate = row.delay_rate;
      row.int_withdraw_rate = row.withdraw_rate;
      row.delay_rate = row.delay_rate >= 0 ? `${row.delay_rate}%` : '-';
      row.withdraw_rate = row.withdraw_rate >= 0 ? `${row.withdraw_rate}%` : '-';
      row.examine_complete = row.examine_complete ? 'Y' : 'N';
      row.good_partner_dt = row.good_partner_dt && row.good_partner_dt.substring(0, 10);
      this.fields.goodPartners.map(f => f.data).forEach(key => {
        if (key !== 'selected' && [undefined, null].includes(row[key])) row[key] = '-';
      });
    },
    resetForm() {
      this.form = this.$utils.clone(this.defaultForm);
    },
    selectAllItem() {
      let selectAll = !this.items.list.every(e => e.selected);
      this.items.list.forEach(e => e.selected = selectAll);
      this.$refs.hotTable.hotInstance.loadData(this.items.list);
    },
    async preDown() {
      this.xlsx.selected = this.items.list.filter(e => e.selected);

      if (!this.xlsx.selected.length) {
        this.xlsx.selected = this.items.list;
      }

      this.xlsx.labels = this.fields.goodPartners.filter(f=> f.data !== 'selected').map(f=> f.name);
      this.xlsx.keys = this.fields.goodPartners.filter(f=> f.data !== 'selected').map(f=> f.data);

      return true;
    },
    showModal(type, row) {
      this.$refs.GoodPartnerModal.showModal(type, row);
    },
    setDate(type){
      this.month = type;
    },
    async DataUpdate() {
      this.busy.dataUpdate = true;
      let j = await this.$api.getJson('/order/reward/goodPartnerStat');
      alert('심사 결과 데이터 갱신이 요청되었습니다. 백그라운드에서 순차적으로 진행됩니다.');
      if (j) {
        this.busy.dataUpdate = false;
        await this.list();
      }
    }
  }
}

</script>